<template>
    <div class="navmb">
		<div class="flex flexa">
            <el-icon :size="20" color="#FF6A00" class="icon">
                <Back class="astyle bgshadow" @click="$router.back()" />
            </el-icon>
			<span class="marl20">消息详情</span>
		</div>
	</div>
    <p class="h20"></p>
    <div v-if="type==1" class="bgf info1" v-loading="loading">
        <h1 class="col1582 font30 tc">{{info.title||'- -'}}</h1>
        <p class="padt14 col7c tc font14 padb30">消息时间： {{info.createTime||'- -'}} </p>
        <div v-html="info.content"></div>
    </div>
    <div v-if="type==2" class="bgf padd20 minh140" v-loading="loading" >
        <div class="info2 font14 col279">
            <h1 class="col1582 font24 padb10">请求加入公司CSI验证</h1>
            <span class="txt1">用户名:</span>
            <span class="padr100 colzhuti">{{info.username}} </span>
            <span class="txt1">手机号:</span>
            <span >{{info.mobile}} </span> <br/>
            <span class="txt1">ID账号:</span>
            <span class="padr100">{{info.accountId}} </span>
            <span class="txt1">时间:</span>
            <span >{{info.applyTime}} </span> <br/>
            <span class="txt1">状态:</span>
            <span class="colblue">{{info.applyStatus }} </span>
            <p class="h80"></p>

            <span v-if="info.applyStatus =='待审核'">
                <el-button type="primary" @click="open(1)" size="small" class="marr10">同意加入</el-button>
                <el-button @click="open(2)" size="small" >拒绝加入</el-button>
            </span>
        </div>
    </div>
    <div v-if="type==3" class="bgf padd20 minh140" v-loading="loading" >
        <div class="info2 font14 col279">
            <h1 class="col1582 font24 padb10">{{info.title}}</h1>
            <span class="txt1">用户名:</span>
            <span class="padr100 colzhuti">{{info.userName}} </span>
            <span class="txt1">手机号:</span>
            <span >{{info.mobile}} </span> <br/>
            <span class="txt1">ID账号:</span>
            <span class="padr100">{{info.id}} </span>
            <span class="txt1">时间:</span>
            <span >{{info.createTime}} </span> <br/>
            <span class="txt1">状态:</span>
            <span class="colblue">{{statussL[info.statuss] }} </span>
            <span class="txt1">邮箱:</span>
            <span class="colblue">{{ info.email }} </span>
            <br />
            <template v-if="info.statuss == 1">
                <span class="txt1">操作:</span>
                <span @click="auditorbangding(info.faUserId, 2)" class="colblue point">通过</span>
                <el-divider direction="vertical"></el-divider>
                <span @click="auditorbangding(info.faUserId, 3)" class="colred point">拒绝</span>
            </template>
            <p class="h80"></p>

            <span v-if="info.statuss == 1&&false">
                <el-button type="primary" @click="open(1)" size="small" class="marr10">同意加入</el-button>
                <el-button @click="open(2)" size="small" >拒绝加入</el-button>
            </span>
        </div>
    </div>
</template>
<script> 
import { msgDetail, bindingAcount } from "@/utils/api1.js"

import { Back } from '@element-plus/icons-vue'
export default {
    components: {
        Back
    },
    data(){
        return{
            loading:false,
            type: this.$route.params.type,
            id: this.$route.params.id,
            info:{},
            statussL:{1:'待审核',2:'已通过',3:'已拒绝'},
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        async auditorbangding(id, status) {
            try {
                let txt = ['', '', '同意', '拒绝', '删除'][status];
                let title = '是否' + txt + '用户加入公司团队的申请？';
                let action = txt + '申请后也可以在公司团队中进行删除或者操作';
                await this.$confirm(action, title, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                })
                const result = await this.$api.user.auditorbangding({ id, status,messageId:this.id })
                if (result) {
                    this.$message.success(result.message)
                    this.getData()
                }
            } catch (e) {
                return console.log(e)
            }
        },
        open(type) {
            let txt = ['','同意','拒绝','删除'][type];
            let title = '是否'+txt+'用户加入公司团队的申请？' ;
            let action = txt+'申请后也可以在公司团队中进行删除或者操作';

            this.$confirm(action, title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$api.user.auditorbangding(this.info.bindingId,type).then(res=>{
                    console.log(res);
                    if(res){
                        this.$message.success(res.message);
                        this.getData();
                    }
                })
            }).catch(() => {});
        },

        getData(){
            this.loading = true;
            console.log(this.id)
            msgDetail({id:this.id}).then(res=>{
                console.log(res);
                if(res){
                    this.info = res;
                }
                this.loading = false;
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.padr100{ padding-right: 100px;}
.txt1{ 
    display: inline-block;
    width: 50px;
    text-align: right;
    color: #656565;
    font-size: 14px;
    padding-right: 20px;
    padding-top: 24px;
}
.info1{
    padding: 33px 120px ;
}
.info2{
    border-color: #F1F1F1;
    border-width: 22px 30px 46px 30px;
    border-style: solid;
    width: 880px;
    margin: 0 auto;
    position: relative;
    padding: 38px 34px;
}
.info2::before{
    position: absolute;
    width: 100%;
    height: 4px;
    background: #FF6A00;
    left: 0;
    top: 0;
    content:'';
}
</style>